<div class="typo-sm flex min-w-0 grow flex-col">
    <div class="mb-8 mt-4 flex min-w-0 grow flex-col gap-8 md:flex-row">
        <span class="w-160 text-text-mid-contrast">{{ label }}</span>
        <div class="min-w-0 grow">
            <ng-content />
        </div>
    </div>
    @if (!hideSeparator) {
        <interacta-separator [thickness]="'soft'" />
    }
</div>
